import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

import './Menu.css';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
        this.state = {};
    }

    render() {
        return (
            <div id="menu">
                <button type={'button'} onClick={() => this.dispatch({ type: 'player_add' })}>{"Add"}</button>
            </div>
        );
    }
}

// Menu.propTypes = {
//     dispatch: PropTypes.func
// };

const mapStateToProps = state => state;

export default connect(mapStateToProps)(Menu);
