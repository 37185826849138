import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Start.css';

class Start extends Component {
    constructor(props) {
        super(props);
        this.socket = props.socket;
        this.state = {
            name: 'Noob #' + Math.floor(Math.random() * 126 + 12),
            roomName: ''
        };
    }

    createRoom() {
        this.socket.emit("player_name", this.state.name);
        this.socket.emit("room_create", this.state.roomName.substr(0, 6));
    }

    onRoomClick(id) {
        console.log('Joining room', this.state.name, id);
        this.socket.emit("player_name", this.state.name);
        this.socket.emit("room_join", id);
    }

    render() {
        return (
            <div id="start">
                <div id="start-name">
                    {'Name:'}
                    <input maxLength="6" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                </div>
                <div id="start-create">
                    <button type={'button'} onClick={() => this.createRoom()}>{"Create room"}</button>
                    <input maxLength="6" onChange={(e) => this.setState({ roomName: e.target.value })} />
                </div>
                <div id="start-rooms">
                    {
                        (this.props.roomNames.length)
                            ? this.props.roomNames.map(key => {
                                const r = this.props.rooms[key];
                                const cantjoin = r.playing || r.players.length >= 4;
                                return (
                                    <div key={r.id} onClick={() => cantjoin ? null : this.onRoomClick(r.id)} className={`start-room ${cantjoin ? '' : 'pointer'}`}>
                                        <p className="start-room-name">{r.name}</p>
                                        <p className="start-room-count">{`${r.players.length} player${r.players.length === 1 ? '' : 's'}`}</p>
                                        {
                                            (r.playing)
                                                ? <p className="start-room-playing">{'Playing'}</p>
                                                : (cantjoin)
                                                    ? <p className="start-room-full">{'Full'}</p>
                                                    : <p className="start-room-idle">{'Waiting'}</p>
                                        }
                                    </div>
                                );
                            })
                            : <div id="start-rooms-empty">{'No rooms'}</div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    rooms: state.rooms,
    roomNames: state.roomNames
});

export default connect(mapStateToProps)(Start);
