import React from 'react';
import ReactDOM from 'react-dom';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer, { initState } from './Redux/Reducer.js';

import App from './App.jsx';

const store = createStore(
    reducer,
    initState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
store.subscribe(() => {});

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('app'));

// import * as servicceWorker from './Init/serviceWorker';
// serviceWorker.unregister();
