import _ from 'lodash';

import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router';

const equalFilterReactComponent = (next, prev) => {
    // Filter react components.
    const filteredNext = _.pickBy(next, (value, key) => {
        return !React.isValidElement(value);
    });

    // Filter react components.
    const filteredPrev = _.pickBy(prev, (value, key) => {
        return !React.isValidElement(value);
    });

    // Return lodash isequal check.
    return _.isEqual(filteredNext, filteredPrev);
};

export default function reduxConnector(mapStateToProps = null, mapDispatchToProps = null, mergeProps = null, options = {}) {
    return connect(mapStateToProps, mapDispatchToProps, mergeProps, _.merge({ areOwnPropsEqual: equalFilterReactComponent, areStatePropsEqual: _.isEqual, areMergedPropsEqual: equalFilterReactComponent, forwardRef: true }, options))
}
