import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DES from '../../../../Redux/DES.js';
import reduxConnector from '../../../../Redux/reduxConnector.js';

import Card from '../Card/Card';

import './Enemy.css';

class Enemy extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.id = props.id;
    }

    render() {
        return (
            <div className={`enemy${this.props.turn ? ' enemy-turn' : ''}${this.props.place ? ' enemy-done' : ''}${this.props.dc ? ' enemy-dc' : ''}`}>
                <div className={'enemy-name'}>{this.props.name}{this.props.place ? <>&nbsp;&nbsp;{`(#${this.props.place})`}</> : ''}</div>
                <div className={'enemy-info'}>
                    <div className={'enemy-cards'}>
                        <div className={'enemy-cards-top'}>
                            {this.props.top.map((c, i) => <Card key={`${this.props.id}-top-${i}`} id={c} empty={!c} />)}
                        </div>
                        <div className={'enemy-cards-bottom'}>
                            {this.props.bottom.map((c, i) => <Card id={'1B'} empty={!c} key={`${this.props.id}-bottom-${i}`} />)}
                        </div>
                    </div>
                    <div className={'enemy-hand'}>
                        {`${this.props.hand}x`}
                        <Card id={'1B'} key={`hand-${this.props.id}`} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => {
    const turnSelector = DES(
        (state, props) => props.id,
        (state, props) => state.room.turn,
        (pid, turn) => pid === turn
    );
    return (state, props) => ({
        dc: !state.enemies[props.id].connected,
        place: state.enemies[props.id].place,
        hand: state.enemies[props.id].hand,
        name: state.enemies[props.id].name,
        top: state.enemies[props.id].top,
        bottom: state.enemies[props.id].bottom,
        turn: turnSelector(state, props),
        test: state.room.turn,
    });
};

export default reduxConnector(mapStateToProps)(Enemy);
