import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import socketIOClient from "socket.io-client";

import End from './components/End/End';
import Menu from './components/Menu/Menu';
import Room from './components/Room/Room';
import Game from './components/Game/Game';
import Start from './components/Start/Start';
import NoServer from './components/NoServer/NoServer';

import 'App.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        window.history.replaceState(null, null, window.location.pathname);

        this.socket = socketIOClient('https://server.shithead.stijnotten.nl');
        // this.socket = socketIOClient('http://127.0.0.1:4001');
        this.socket.on("welcome", id => this.props.dispatch({ type: 'server_socket_id', id }));
        this.socket.on("room_join", (room) => this.props.dispatch({ type: 'server_room_join', room }));
        this.socket.on("room_update", (room) => this.props.dispatch({ type: 'server_room_update', room }));
        this.socket.on("rooms_update", (rooms) => this.props.dispatch({ type: 'server_rooms_update', rooms }));
        this.socket.on("board_update", (board) => this.props.dispatch({ type: 'server_board_update', board }));
        this.socket.on("player_update", (player) => this.props.dispatch({ type: 'server_player_update', player }));
        this.socket.on("enemies_update", (enemies) => this.props.dispatch({ type: 'server_enemies_update', enemies }));
    }

    render() {
        if (!this.props.socket) {
            return <NoServer />;
        }
        if (!this.props.room) {
            return <Start socket={this.socket} />;
        }
        if (this.props.shithead) {
            return <End socket={this.socket} />;
        }
        if (!this.props.playing) {
            return <Room id={this.props.room} socket={this.socket} />;
        }
        return (
            <>
                <DndProvider backend={HTML5Backend}>
                    <Game socket={this.socket} />
                    {/* <Menu /> */}
                </DndProvider>
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    room: state.room.id,
    socket: state.socket,
    playing: state.room.playing,
    shithead: state.room.shithead,
});

export default connect(mapStateToProps)(App);
