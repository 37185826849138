import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Room.css';

class Room extends Component {
    constructor(props) {
        super(props);
        this.socket = props.socket;
        this.state = {};
    }

    startGame() {
        this.socket.emit('game_start', this.props.id);
    }

    render() {
        return (
            <>
                <div id="room-name">{this.props.name}</div>
                <div id="room">
                    <div id="room-players">
                        <div id="room-count">{`Players: ${this.props.players.length}`}</div>
                        {this.props.players.map(p => p.name).join(', ')}
                    </div>
                    <div id="room-status">
                        {
                            (this.props.admin)
                                ? <button type={'button'} disabled={this.props.players.length < 2} onClick={() => this.startGame()}>{"Start game"}</button>
                                : 'Waiting until the admin starts the game'
                        }
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    name: state.room.name,
    players: state.room.players,
    admin: state.socket === state.room.admin
});

export default connect(mapStateToProps)(Room);
