import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, DragSource } from 'react-dnd';
import DES from '../../../../Redux/DES.js';

import Test from './img/3D.svg';

import './Card.css';

const reqSvgs = require.context('./img', true, /\.svg$/);
const cards = reqSvgs.keys().reduce((images, path) => ({ ...images, [path]: reqSvgs(path) }), {});

var cardSource = {
    beginDrag(props) {
        return {
            id: props.id,
            board: props.board,
            onDrop: props.onDrop
        };
    },
    endDrag(dropResult, monitor) {
        const item = monitor.getItem();
        const drop = monitor.didDrop();
        if (drop) {
            item.onDrop(item.id);
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    };
}

class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.props.empty) {
            return <div className={`card card-empty`} />;
        }
        const svg = cards[`./${this.props.id}.svg`].default;
        const wrp = this.props.draggable ? this.props.connectDragSource : (e) => e;
        return wrp(
            <div className={`card card-${this.props.id} ${this.props.isDragging ? 'card-drag' : ''}`} style={{ transform: `rotate(${this.props.r || 0}deg)` }}>
                <img src={svg} alt={"sfc"} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    rid: state.room.id,
    board: state.board
});

const mapDispatchToProps = (dispatch, props) => ({
    onDrop: (cid) => {
        const from = props.from || 'hand';
        const idid = (from === 'bottom') ? props.index : cid;
        props.socket.emit('game_play', idid, from);
    }
});

const dragger = DragSource('CARD', cardSource, collect)(Card);
export default connect(mapStateToProps, mapDispatchToProps)(dragger);
