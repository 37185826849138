import React, { Component } from 'react';
import { connect } from 'react-redux';

import './NoServer.css';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id="noserver">
                <h2>{'Unable to access Shithead server'}</h2>
                <h3>{'Please try again later'}</h3>
                <button type="button" onClick={() => window.location.reload()}>{'Try again'}</button>
            </div>
        );
    }
}

// Menu.propTypes = {
//     dispatch: PropTypes.func
// };

const mapStateToProps = state => state;

export default connect(mapStateToProps)(Menu);
