import _ from 'lodash';

const initState = {
    player: {
        hand: [],
        name: 'Unknown'
    },
    socket: null,
    rooms: {},
    roomNames: [],
    room: {
        id: null,
        name: '',
        players: [],
        playing: false
    },
    enemies: {},
    board: []
};

const reducer = (state = initState, action) => {
    let d, c, o, p, b, h, i;
    console.log('Received ' + action.type);
    switch (action.type) {
        case '@@INIT':
            return { ...state };

        case 'server_board_update':
            return { ...state, board: [ ...action.board ] };

        case 'server_player_update':
            return { ...state, player: { ...state.player, ...action.player } };

        case 'server_socket_id':
            return { ...state, socket: action.id };

        case 'server_rooms_update':
            return { ...state, rooms: action.rooms, roomNames: Object.keys(action.rooms) };

        case 'server_room_join':
            window.history.pushState('-', '-', `#${action.room.id}`);
            return { ...state, rooms: {}, roomNames: [], room: { ...state.room, ...action.room } };

        case 'server_room_update':
            return { ...state, room: { ...state.room, ...action.room } };

        case 'server_enemies_update':
            return { ...state, enemies: { ...action.enemies } };

        default: return { ...state };
    }
};

export {
    initState
};

export default reducer;
