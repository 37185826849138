import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DES from '../../../../Redux/DES.js';
import reduxConnector from '../../../../Redux/reduxConnector.js';

import Card from '../Card/Card';

import './Player.css';

class Player extends Component {
    constructor(props) {
        super(props);
        this.socket = props.socket;
        this.state = {};
    }

    render() {
        if (this.props.place) {
            return (
                <div className={`player`}>
                    <div className={`player-place`}>{`# ${this.props.place}`}</div>
                </div>
            );
        }

        const noTop = this.props.top.every(t => !t);
        return (
            <div className={`player player-${this.props.turn ? 'turn' : 'idle'}`}>
                {this.props.hand.map(c => <Card key={c} id={c} draggable={this.props.turn} socket={this.socket} />)}
                <div className={'player-table'}>
                    <div className={`player-table-top ${noTop ? 'low' : ''}`}>
                        {this.props.top.map((c, i) => <Card key={`${this.props.id}-top-${i}`} id={c} empty={!c} from={'top'} socket={this.socket} draggable={this.props.turn && this.props.hand.length === 0} />)}
                    </div>
                    <div className={'player-table-bottom'}>
                        {this.props.bottom.map((c, i) => <Card id={'1B'} index={i} empty={!c} from={'bottom'} key={`${this.props.id}-bottom-${i}`} socket={this.socket} draggable={this.props.turn && noTop && this.props.hand.length === 0} />)}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    place: state.player.place,
    hand: state.player.hand,
    top: state.player.top || [],
    bottom: state.player.bottom || [],
    turn: state.room.turn === state.player.sid
});

export default reduxConnector(mapStateToProps)(Player);
