import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import DES from '../../Redux/DES.js';

import Enemy from './components/Enemy/Enemy';
import Board from './components/Board/Board';
import Player from './components/Player/Player';
import Controls from './components/Controls/Controls';

import './Game.css';

class Game extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.socket = props.socket;
        this.dispatch = props.dispatch;
    }

    render() {
        return (
            <>
                <Board socket={this.socket} />
                <Player socket={this.socket} />
                {/* <Controls /> */}
                <div id="enemies">
                    {this.props.enemies.map(i => <Enemy key={i} id={i} />)}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    enemies: Object.keys(state.enemies)
});

export default connect(mapStateToProps)(Game);
