import React, { Component } from 'react';
import { connect } from 'react-redux';
import DES from '../../../../Redux/DES.js';

import './Controls.css';

class Controls extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id="controls">
                <button type={'button'} onClick={() => this.props.dispatch({ type: 'nothing' })}>{"Take"}</button>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => state;

export default connect(mapStateToProps)(Controls);
