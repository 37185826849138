import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';
import DES from '../../../../Redux/DES.js';
import reduxConnector from '../../../../Redux/reduxConnector.js';

import Card from '../Card/Card';

import './Board.css';

var boxTarget = {
    canDrop(props, monitor) {
        const { id: card, board } = monitor.getItem();
        const top = board[board.length - 1];

        return true;

        // if (!top) {
        //     return true;
        // }

        // let [ topValue, topface ] = top.split('');
        // const [ cardValue, cardface ] = card.split('');

        // if ([ '2', '3' ].includes(topValue)) {
        //     return true;
        // }

        // if (topValue === 'T') topValue = 10;
        // if (topValue === 'J') topValue = 11;
        // if (topValue === 'q') topValue = 12;
        // if (topValue === 'k') topValue = 13;

        // if (topValue === '7') {
        //     return cardValue <= 7;
        // }

        // if (cardValue < topValue) {
        //     return false;
        // }
        // return true;
    },

    drop(props) {

    }
};

function collect(connect, monitor) {
    return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        connectDropTarget: connect.dropTarget()
    };
}

class Board extends Component {
    constructor(props) {
        super(props);
        this.socket = props.socket;
        this.state = {};
    }

    render() {
        const dropClass = this.props.isOver ? (this.props.isOver && this.props.canDrop ? 'board-drop' : 'board-nodrop') : '';
        return this.props.connectDropTarget(
            <div id="board" className={dropClass}>
                <div id="pile">
                    <em>{`${this.props.deck}x`}</em>
                    <Card id="1B" />
                </div>
                {this.props.cards.map((c, i) => {
                    const r = i * (i % 2 === 0 ? 3 : -3);
                    return c ? <Card r={r} key={c} id={c} /> : <Card r={r} key={`empty-${i}`} empty />;
                })}
                <div id="take">
                    <button type={'button'} disabled={!this.props.turn || this.props.cards.length === 0} onClick={() => this.socket.emit('game_take', this.props.rid)}>{"Take"}</button>
                    {/* <button type={'button'} disabled={!this.props.turn || this.props.cards.length === 0} onClick={() => this.props.dispatch({ type: 'player_take' })}>{"Take"}</button> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    rid: state.room.id,
    cards: state.board,
    deck: state.room.deck,
    turn: state.room.turn === state.player.sid
});

export default DropTarget('CARD', boxTarget, collect)(reduxConnector(mapStateToProps)(Board));
