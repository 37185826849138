import React, { Component } from 'react';
import { connect } from 'react-redux';

import './End.css';

class End extends Component {
    constructor(props) {
        super(props);
        this.socket = props.socket;
        this.state = { };
    }

    render() {
        const sh = this.props.players.shift();
        console.log('End', sh, this.props.players);
        return (
            <div id="end">
                <div id="end-shithead">
                    {`💩`}&nbsp;&nbsp;{sh.name}&nbsp;&nbsp;{`💩`}
                </div>
                <div id="end-players">
                    <ol>
                        {this.props.players.map(p => <li key={p.name}>{p.name}</li>)}
                    </ol>
                </div>
                <button type="button" onClick={() => window.location.reload()}>{'Play again'}</button>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    players: [ state.player, ...Object.values(state.enemies) ].sort((a, b) => !a.place ? -1 : (a.place - b.place)),
    isShithead: state.room.shithead === state.player.sid
});

export default connect(mapStateToProps)(End);
